


























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { forgotPassword } from '~/api/auth'
import { ModalStore, INotificationParams } from '~/store/modal'
import { prettifyApiError } from '~/services/prettify-api-error'

@Component
export default class ForgotPasswordPage extends Vue {
    email = ''
    step = 1
    error = ''

    @ModalStore.Action('showNotification') showNotification: (params: INotificationParams) => void

    async forgotPassword(email: string) {
        try {
            await forgotPassword(this.email)
            this.step++
        } catch (err) {
            this.error = prettifyApiError(err)
            this.showNotification({ name: 'error', title: 'Something went wrong', content: 'Please try again later, or contact support' })
        }
    }
}
